import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Logo from './Logo';
import Button from '../Button';
import BurgerMenu from '../BurgerMenu';

import { FaWhatsapp } from 'react-icons/fa';

const BookingButton = styled.div
`
display: inline;
`

const Header = () => (
  <StaticQuery query={
    graphql
    `
    {
        allWordpressMenusMenusItems(filter:{name:{eq:"main menu"}}){
            edges{
                node{
                    items{
                        title
                        slug
                    }
                    name
                }
            }
        }
    }
    `
  }render={props=>(
    <header className="header-box sticky top-0 bg-white shadow">
      {/* <BurgerMenu className="flex flex-col sm:flex-row"/> */}
      <div className="container flex flex-row justify-between mx-auto py-4 px-4">
        <div className="flex items-center sm:pl-10">
          <div className="w-12 mr-3">
            <Link to={'/'}>
              <Logo />
            </Link>
            {/* <LogoIcon /> */}
          </div>
          <div className="grid grid-flow-row">
            <Link to={'/'} className="header-link">
              <p className="font-semibold -mb-2">MARA JUARA</p>
              <p className="text-sm -my-2">House of Success</p>
            </Link>
          </div>
        </div>
        <div className="flex mt-4 ml-10 hidden md:inline-block">
          {props.allWordpressMenusMenusItems.edges[0].node.items.map(item=>(
            <Link className="px-4" to={'/' + item.slug} key={item.title}>
              {item.title}
            </Link>
            // <AnchorLink className="px-4" href="#services">
            //   Services
            // </AnchorLink>
            // <AnchorLink className="px-4" href="#stats">
            //   Stats
            // </AnchorLink>
            // <AnchorLink className="px-4" href="#testimonials">
            //   Testimonials
            // </AnchorLink>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center mx-auto float-right">
          <a href="https://api.whatsapp.com/send?phone=628111688890&text=Halo%20saya%20tertarik%20dengan%20kost%20Mara%20Juara!">
            <Button className="w-32 lg:w-full text-sm md:inline">
              <BookingButton>
                <span className="hidden md:inline" ><FaWhatsapp style={{display: "inline"}}/></span>&nbsp;Booking Sekarang!
              </BookingButton>
            </Button>
          </a>
        </div>
      </div>
    </header>
  )} />
);

export default Header;
