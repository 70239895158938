import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { graphql, StaticQuery, Link } from 'gatsby';

class BurgerMenu extends Component {
    
    showSettings (event) {
        event.preventDefault();
    }

    render () {
        // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
        return (
        <Menu left>
           <StaticQuery query={
                graphql
                `
                {
                    allWordpressMenusMenusItems(filter:{name:{eq:"main menu"}}){
                        edges{
                            node{
                                items{
                                    title
                                    slug
                                }
                                name
                            }
                        }
                    }
                }
                `
            }render={props=>(
                <div className="flex mt-4 sm:mt-0 hidden md:inline-block">
                    {props.allWordpressMenusMenusItems.edges[0].node.items.map(item=>(
                        <a className="px-4" to={'/' + item.slug} key={item.title}>
                            {item.title}
                        </a>
                        // <AnchorLink className="px-4" href="#services">
                        //   Services
                        // </AnchorLink>
                        // <AnchorLink className="px-4" href="#stats">
                        //   Stats
                        // </AnchorLink>
                        // <AnchorLink className="px-4" href="#testimonials">
                        //   Testimonials
                        // </AnchorLink>
                    ))}
                </div>
            )} />
        </Menu>
        );
    }
}

export default BurgerMenu;