import React from 'react';
import { Link } from 'gatsby';
import { FaWhatsapp, FaInstagram, FaFacebook, FaRegEnvelope } from 'react-icons/fa';

const Footer = () => (
  <footer className="container mx-auto pt-10 pb-2 px-3 mt-2 mb-2 text-gray-800">
    <div className="md:flex mx-auto">
      <div className="flex-1 px-8 text-center md:text-left">
        <h2 className="text-lg font-semibold">Kost Eksklusif Mara Juara Depok</h2>
        <p className="mt-2 md:mt-5 text-center text-base md:text-left">
          Jl Juanda Raya No. 15, Depok, Jawa Barat
          (Patokan di belakang Dapur Cokelat dan Soto Kudus Menara)
        </p>
        {/* <Link to="/tentang-kami" className="text-md md:text-lg">Lihat Peta</Link> */}
        <div className="mapouter">
          <div className="gmap_canvas">
            <div className="map-responsive">
            <iframe height="200" width="10%" id="gmap_canvas" src="https://maps.google.com/maps?q=Mara%20Juara%20Depok&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"> 
            </iframe>
            </div>
          </div>
          <style>.mapouter{`position:relative;text-align:right;height:200px;width:10%`}.gmap_canvas {`overflow:hidden;background:none!important;height:500px;width:100%`} </style>
        </div>
      </div>
      <div className="flex-1 px-3 text-center mt-5 lg:mt-0">
        <h2 className="text-lg font-semibold mb-0 md:mb-5">Tautan Penting</h2>
        <ul className="mt-2 md:mt-4 leading-loose">
          <li>
            <a href="https://api.whatsapp.com/send?phone=628111688890&text=Halo%20saya%20tertarik%20dengan%20kost%20Mara%20Juara!">
              Booking Kamar
            </a>
          </li>
          <li>
            <Link to={'/tentang-kami'}>
                Tentang Kami
            </Link>
          </li>
          <li>
            <Link to={'/blog'}>
                Artikel
            </Link>
          </li>
          
          <li>
            <Link to={'/partner-kami'}>
                Partner Kami
            </Link>
          </li>
          
        </ul>
      </div>
      <div className="flex-1 px-3 text-center mt-5 lg:mt-0">
        <h2 className="text-lg font-semibold mb-0 md:mb-5">Hubungi Kami</h2>
        <ul className="mt-2 md:mt-4 leading-loose">
          <li>
            <a href="https://api.whatsapp.com/send?phone=628111688890&text=Halo%20saya%20tertarik%20dengan%20kost%20Mara%20Juara!"><FaWhatsapp style={{display: "inline"}} />&nbsp;Whatsapp</a>
          </li>
          <li>
            <a href="mailto:marajuaraestate@gmail.com"><FaRegEnvelope style={{display: "inline"}} />&nbsp;Email</a>
          </li>
          <li>
            <a href="https://instagram.com/marajuaraestate"><FaInstagram style={{display: "inline"}} />&nbsp;Instagram</a>
          </li>
          <li>
            <a href="https://www.facebook.com/Mara-Juara-House-of-Success-107680284251803/"><FaFacebook style={{display: "inline"}} />&nbsp;Facebook</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
