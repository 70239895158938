import React from 'react';

const Watermark = () => (
  <footer className="container mx-auto px-3 mb-3 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <p className="text-sm font-medium text-center md:text-right">&#169; Mara Juara Estate 2020</p>
      </div>
    </div>
  </footer>
);

export default Watermark;
